import styled, { css } from 'styled-components';
// import PropTypes from 'prop-types';
// import { pxToEm } from 'src/lib/breakpoints';
import { rem } from 'src/lib/units';
import { makeContainer } from 'src/lib/mixins';

// https://github.com/styled-components/styled-components/issues/1546
// https://stackoverflow.com/questions/49618997/idiomatic-way-to-share-styles-in-styled-components

const maxWidth = 1200;

// const maxWidth = 960;

const container = `
    margin: 0 auto;
    max-width: ${maxWidth}px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: ${props => props.small ? rem(100) : null } ;
    padding-bottom: ${props => props.small ? rem(100) : null };
    padding-top: ${props => props.medium ? rem(200) : null } ;
    padding-bottom: ${props => props.medium ? rem(200) : null } ;

`;

const Container = styled.div`
    margin: 0 auto;
    max-width: ${maxWidth}px;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: ${props => props.small ? rem(100) : null } ;
    padding-bottom: ${props => props.small ? rem(100) : null };
    padding-top: ${props => props.medium ? rem(200) : null } ;
    padding-bottom: ${props => props.medium ? rem(200) : null } ;
    
    
    // ${props => props.size === 'medium' && css`
    //     padding-top: rem(200);
    //     padding-bottom: rem(200);
    // `}
`;


const SectionContainer = Container.withComponent('section');

export const ArticleContainer = styled.article`
    ${makeContainer(1100)}// was 760, 960
    margin-bottom: ${rem(60)};
`;

export  { Container, container, SectionContainer };