import React from "react"
import Layout from 'components/common/layout';
import { Heading, Text } from 'components/common/typography';
import { ArticleContainer } from 'components/common/layout/container';
import { LinkAction } from 'components/common/link';
import SEO from 'components/common/seo';

export default () => (
    <Layout>
        <SEO title="404: Not found" />
        <ArticleContainer>
            <Heading>404: Page Not Found</Heading>
            <Text>Ooops! This page doesn't exist.</Text>
            <LinkAction to="/" size="large">This way home</LinkAction>
        </ArticleContainer>
    </Layout>
);
